import request from '@/utils/request'


// 查询防伪列表
export function listCounterfeiting(query) {
  return request({
    url: '/anti/anti-counterfeiting/list',
    method: 'get',
    params: query
  })
}

// 查询防伪分页
export function pageCounterfeiting(query) {
  return request({
    url: '/anti/anti-counterfeiting/page',
    method: 'get',
    params: query
  })
}

// 查询防伪详细
export function getCounterfeiting(data) {
  return request({
    url: '/anti/anti-counterfeiting/detail',
    method: 'get',
    params: data
  })
}

// 新增防伪
export function addCounterfeiting(data) {
  return request({
    url: '/anti/anti-counterfeiting/add',
    method: 'post',
    data: data
  })
}

// 修改防伪
export function updateCounterfeiting(data) {
  return request({
    url: '/anti/anti-counterfeiting/edit',
    method: 'post',
    data: data
  })
}

// 删除防伪
export function delCounterfeiting(data) {
  return request({
    url: '/anti/anti-counterfeiting/delete',
    method: 'post',
    data: data
  })
}
